import React, { useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { Box, AspectRatio, List, ListItem, ListItemButton, ListItemDecorator, ListItemContent, Accordion, AccordionDetails } from "@mui/joy";
import MicIcon from '@mui/icons-material/Mic';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import GradingIcon from '@mui/icons-material/Grading';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GetHelp from "../components/RenderHelpDisplay";


function NavigationBar() {
    const [ pastReportsExpanded, setPastReportsExpanded ] = useState(true);
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname.includes(path);
    }

    return <Box sx={{display: "flex", height: "100vh"}}>
        <Box sx={{display: {xs: "none", sm: "flex"}, flexDirection: "column", flexGrow: 1, borderRight: "2px solid #f1f3f4"}}>
            <AspectRatio objectFit='contain' sx={{ padding: '5px', paddingLeft: "10px", paddingRight: "10px", backgroundColor:'inherit' }} variant='plain'>
                <img src="/assets/backgroundlogo.png" alt='Logo'/>
            </AspectRatio>
            <List component="nav" sx={{ backgroundColor: 'primary.main', padding: '10px' }}>
                <ListItem>
                    <ListItemButton component={NavLink} to="/new-report" sx={{ backgroundColor: isActive('/new-report') ? '#f1f3f4' : 'inherit' }}>
                        <ListItemDecorator> <MicIcon color={isActive('/new-report') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
                        <ListItemContent sx={{ fontSize: '1.3rem', fontWeight: isActive('/new-report') ? '700' : 'inherit', p: 1 }}> Current Report </ListItemContent>
                    </ListItemButton>
                </ListItem>

                <ListItem sx={{display: "block", backgroundColor: pastReportsExpanded ? '#f1f3f4' : 'inherit'}}>
                    <ListItemButton onClick={() => setPastReportsExpanded(!pastReportsExpanded)}>
                        <ListItemDecorator> <HistoryIcon color={'inherit'} fontSize='xl3'/></ListItemDecorator>
                        <ListItemContent sx={{ fontSize: '1.3rem', fontWeight: isActive('/past-reports') ? '700' : 'inherit', p: 1 }}> Past Reports </ListItemContent>
                        {pastReportsExpanded ? <KeyboardArrowUpIcon fontSize='xl3'/> : <KeyboardArrowDownIcon fontSize='xl3'/>}
                    </ListItemButton>
                    <Accordion expanded={pastReportsExpanded} sx={{display: pastReportsExpanded ? "block" : "none"}}>
                        <AccordionDetails>
                            <ListItemButton component={NavLink} to="/past-reports/drafts">
                                <ListItemDecorator> <EditIcon color={isActive('/past-reports/drafts') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
                                <ListItemContent sx={{ fontSize: '1.3rem', fontWeight: isActive('/past-reports/drafts') ? '700' : 'inherit', p: 1 }}> Drafts</ListItemContent>
                            </ListItemButton>
                            <ListItemButton component={NavLink} to="/past-reports/in-review">
                                <ListItemDecorator> <GradingIcon color={isActive('/past-reports/in-review') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
                                <ListItemContent sx={{ fontSize: '1.3rem', fontWeight: isActive('/past-reports/in-review') ? '700' : 'inherit', p: 1 }}> In Review</ListItemContent>
                            </ListItemButton>
                            <ListItemButton component={NavLink} to="/past-reports/approved">
                                <ListItemDecorator> <AssignmentTurnedInIcon color={isActive('/past-reports/approved') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
                                <ListItemContent sx={{ fontSize: '1.3rem', fontWeight: isActive('/past-reports/approved') ? '700' : 'inherit', p: 1 }}> Approved</ListItemContent>
                            </ListItemButton>
                        </AccordionDetails>
                    </Accordion>
                </ListItem>

                <ListItem>
                    <ListItemButton component={NavLink} to="/account" sx={{ backgroundColor: isActive('/account') ? '#f1f3f4' : 'inherit' }}>
                        <ListItemDecorator> <SettingsIcon color={isActive('/account') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
                        <ListItemContent sx={{ fontSize: '1.3rem', fontWeight: isActive('/account') ? '700' : 'inherit', p: 1 }}> Settings </ListItemContent>
                    </ListItemButton>
                </ListItem>

                <ListItem>
                    <ListItemButton component={NavLink} to="/sign-out" sx={{ backgroundColor: isActive('/logout') ? '#f1f3f4' : 'inherit' }}>
                        <ListItemDecorator> <LogoutIcon color={isActive('/sign-out') ? '600' : 'inherit'} fontSize='xl3'/></ListItemDecorator>
                        <ListItemContent sx={{ fontSize: '1.3rem', fontWeight: isActive('/sign-out') ? '700' : 'inherit', p: 1 }}> Sign Out </ListItemContent>
                    </ListItemButton>
                </ListItem>
            </List>
            <GetHelp />
        </Box>
    </Box>

}

export default NavigationBar;