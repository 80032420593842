
// Function for returning the form inputs decided. Takes in user_edit dictionary and generated_result dictionary
export function newTotalInputs(user_edit_dictionary, generated_dictionary) {
    const newInitialInputs = {};
    // Check if generated exists or not first
    if (!generated_dictionary) {
        return user_edit_dictionary;
    }

    if (!user_edit_dictionary) {
        return generated_dictionary
    }

    // Iterate through sections first
    for (let field in generated_dictionary) {
        if (typeof generated_dictionary[field] === 'boolean' && field in user_edit_dictionary) {
            newInitialInputs[field] = generated_dictionary[field]
        }
        else if (!(field in user_edit_dictionary) || (user_edit_dictionary[field] !== undefined && user_edit_dictionary[field].length <= 0)) {
            newInitialInputs[field] = generated_dictionary[field]
        } else {
            newInitialInputs[field] = user_edit_dictionary[field]
        }
        delete user_edit_dictionary[field]
    }
    let finalInputs = {
        ...user_edit_dictionary,
        ...newInitialInputs,
    }

    return finalInputs;

}