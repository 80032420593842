

import React from "react";
import { Card, Typography, Box } from "@mui/joy";
import { axiosInstance } from "../helpers/AxiosConfiguration";
import { convertTime } from "../helpers/TimeConversionHelper";
import { GetFeedbackIcon } from "../helpers/IconGeneration";
import { getBackground, getColor } from "../helpers/GetColors";


function FeedbackCard({status, comment, time}) {


    return <Box sx={{display: "flex", my: 2, justifyContent: "space-between"}}>
        <Box />
        <Card sx={{width: "20%"}}>
            <Box>
                <Box sx={{display: "flex", width: "fit-content", p: 1, backgroundColor: getBackground(status), borderRadius: "8px"}}>
                    <GetFeedbackIcon />
                    <Typography level="title-lg" color={getColor(status)}> Status: {status.charAt(0).toUpperCase() + status.slice(1)} </Typography>
                </Box>
                <Box sx={{marginLeft: 1}}>
                    <Typography> Reviewed at: {convertTime(time)}</Typography>
                    <Typography> Comment: {comment}</Typography>
                </Box>
            </Box>
        </Card>
    </Box>
}


export default FeedbackCard;