import React, { Component } from "react";
import { AlertContext } from "../../components/AlertContext";
import { Box, List, ListItem, Typography, Checkbox, IconButton, Input } from "@mui/joy";
import { axiosInstance } from "../../helpers/AxiosConfiguration";
import { calculateAndConvertTime, retrieveDateStringFromISO, retrieveTimeStringFromISO } from "../../helpers/TimeConversionHelper";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import PastReportsExpanded from "../../components/PastReportsExpanded";

class PastReports extends Component {
    static contextType = AlertContext;

    constructor(props) {
        super(props);
        this.state = {
            visitsGenerated: [],
            groupedVisits: {},
            dateRange: '3 months',
            page: 1,
            totalVisits: 0,
            size: 10,
            favorites: {},
            selectedVisit: null,
        };
    }

    toggleFavorite = (itemId) => {
        this.setState(prevState => ({
            favorites: {
                ...prevState.favorites,
                [itemId]: !prevState.favorites[itemId]
            }
        }));
    }

    componentDidMount() {
        this.fetchVisits(this.state.dateRange, this.state.page);
    }


    fetchVisits = async (timeRange, page) => {
        try {
            const response = await axiosInstance.get('/api/v1/visits', {
                params: {
                    'from_time': calculateAndConvertTime(timeRange),
                    'to_time': calculateAndConvertTime('current'),
                    'sort': '-created_at',
                    'page': page,
                    'size': this.state.size,
                    "status": this.props.sort,
                }
            });

            const { items, total, page: currentPage, size } = response.data;

            const groupedVisits = this.groupVisitsByDate(items);

            this.setState({
                visitsGenerated: items,
                groupedVisits: groupedVisits,
                totalVisits: total,
                page: currentPage,
                size: size,
            });
        } catch (error) {
            this.context.addAlert("There was an error retrieving your visits", "danger");
        }
    }

    groupVisitsByDate(visits) {
        const grouped = {};
        visits.forEach(visit => {
            const date = new Date(`${visit.created_at}Z`);
            const header = this.getDateHeader(date);
            if (!grouped[header]) {
                grouped[header] = [];
            }
            grouped[header].push(visit);
        });
        return grouped;
    }

    getDateHeader(date) {
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        const lastWeek = new Date(now);
        lastWeek.setDate(now.getDate() - 7);
        const lastMonth = new Date(now);
        lastMonth.setMonth(now.getMonth() - 1);
        const lastThreeMonths = new Date(now);
        lastThreeMonths.setMonth(now.getMonth() - 3);


        if (date.getDate() === now.getDate()) {
            return "1. Today";
        } else if (date.getDate() === yesterday.getDate()) {
            return "2. Yesterday";
        } else if (date >= lastWeek) {
            return "3. Last Week";
        } else if (date >= lastMonth) {
            return "4. Last Month";
        } else if (date >= lastThreeMonths) {
            return "5. Last 3 Months";
        } else {
            return "6. Older";
        }
    }

    formatDate(dateString) {
        const date = new Date(`${dateString}`);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    }

    handlePageChange = (direction) => {
        const newPage = direction === 'next' ? this.state.page + 1 : this.state.page - 1;
        if (newPage > 0 && newPage <= Math.ceil(this.state.totalVisits / this.state.size)) {
            this.fetchVisits(this.state.dateRange, newPage);
        }
    }

    handleVisitClick = (visit) => {
        this.setState({ selectedVisit: visit });
    }

    handleCloseVisit = () => {
        this.setState({ selectedVisit: null });
        this.fetchVisits(this.state.dateRange, this.state.page);
    }

    render() {
        const totalPages = Math.ceil(this.state.totalVisits / this.state.size);

        const sortedHeaders = Object.keys(this.state.groupedVisits).sort();

        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                    <Input
                        endDecorator={<ZoomInIcon />}
                        placeholder="Search note"
                        sx={{ 
                            width: '80%',
                            borderRadius: '50px',
                            '& .MuiInput-input': {
                                paddingLeft: '12px',
                            },
                            visibility: "hidden"
                        }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ mr: 2 }}>
                            {`${this.state.page}-${totalPages} of ${this.state.totalVisits} items`}
                        </Typography>
                        <IconButton 
                            onClick={() => this.handlePageChange('prev')} 
                            disabled={this.state.page === 1}
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <IconButton 
                            onClick={() => this.handlePageChange('next')} 
                            disabled={this.state.page === totalPages}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                </Box>
                {!this.state.selectedVisit ? (
                    <List sx={{ flexGrow: 1, overflow: 'auto' }}>
                        {sortedHeaders.map((header) => (
                            <React.Fragment key={header}>
                                <Box sx={{display: 'flex', p: 1, backgroundColor: "#EDF5FD"}}>
                                    <Typography fontWeight={'bold'}>{header.slice(3)}</Typography>
                                </Box>
                                {this.state.groupedVisits[header].map((item, index) => (
                                    <ListItem 
                                    key={item.id || index}
                                    sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        alignItems: 'center',
                                        borderBottom: '1px solid #e0e0e0',
                                        py: 2,
                                        position: 'relative',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => this.handleVisitClick(item)}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, maxWidth: '30%' }}>
                                            <Typography sx={{ ml: 2, flex: 1, display: "none"}} noWrap >{item.title || 'No Title'}</Typography>
                                        </Box>
                                        <Box sx={{ 
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '40%',
                                            textAlign: 'center'
                                        }}>
                                            <Typography noWrap>
                                                {(item.title !== null && item.title !== '' && item.title !== 'null') ? item.title : `Report ${retrieveDateStringFromISO(item.details.incident_occurred_at)}, ${retrieveTimeStringFromISO(item.details.efrt_activated_at)}`}
                                            </Typography>
                                        </Box>
                                        <Typography sx={{ ml: 2, minWidth: '200px', textAlign: 'right', maxWidth: '30%' }} noWrap>
                                            {this.formatDate(`${retrieveDateStringFromISO(item.details.incident_occurred_at)}T${retrieveTimeStringFromISO(item.details.efrt_activated_at)}`)}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </React.Fragment>
                        ))}
                        {Object.keys(this.state.groupedVisits).length === 0 && (
                            <ListItem>No visits to display</ListItem>
                        )}
                    </List>
                ) : (
                    <Box sx={{ flexGrow: 1, overflow: 'none', width: '100%'}}>
                        <PastReportsExpanded 
                            visit={this.state.selectedVisit}
                            edit={this.props.sort === 'draft'}
                            onClose={() => this.setState({ selectedVisit: null })}
                        />
                    </Box>
                )}
            </Box>
        );
    }
}

export default PastReports;