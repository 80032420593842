



export const getBackground = (status) => {
    return (status === "review" ? "#fef9c3ff" : 
        status === "approved" ? "#dcfce7ff" :
        status === "denied" ? "#ffecefff":
        "#ffecefff")
}


export const getColor = (status) => {
    return (status === "review" ? "#905b20" : 
        status === "approved" ? "#1d6738" :
        status === "denied" ? "#f43f5f":
        "#f43f5f")
}

