import { Modal, ModalDialog, DialogTitle, DialogContent, Button, FormControl, FormLabel, Input } from "@mui/joy";
import React, { useContext, useEffect, useState } from "react";
import { axiosInstance } from "../helpers/AxiosConfiguration";
import { AlertContext } from "./AlertContext";


function PublishingModal({open, formAction, setClose, visitID}) {
    const { addAlert } = useContext(AlertContext);
    const [comment, setComment] = useState("");
    const [title, setTitle] = useState("");


    useEffect(() => {
        if (formAction === 'review') {
            setTitle("Send for review?");
        } else if (formAction === 'deny') {
            setTitle("Send back for changes?");
        } else if (formAction === 'approve') {
            setTitle("Approve incident report?")
        }
    }, [formAction])



    return <Modal open={open} onClose={() => setClose()}>
        <ModalDialog>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent> Attach any additional message alongside your review.</DialogContent>
            <form onSubmit={async () => {
                console.log(formAction);
                switch(formAction) {
                    case 'review' : {
                        console.log("review?")
                        await axiosInstance.post(`/api/v1/visits/${visitID}/statuses`, {
                            "value": 'review',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Report sent for review!", "success");
                            setClose();
                        }).catch(error => {
                            addAlert("Unable to send for review.", "danger");
                            setClose();
                        })
                    }
                    case 'deny': {
                        console.log("deny")
                        await axiosInstance.post(`/api/v1/visits/${visitID}/statuses`, {
                            "value": 'denied',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Report approval denied and sent back for editing!", "success");
                            setClose();
                        }).catch(error => {
                            console.log(error)
                            addAlert("Unable to deny.", "danger");
                            setClose();
                        })
                    }
                    case 'approve': {
                        console.log("approve")
                        await axiosInstance.post(`/api/v1/visits/${visitID}/statuses`, {
                            "value": 'approved',
                            "comment": comment,
                        }).then( response => {
                            addAlert("Report approved!", "success");
                            setClose();
                        }).catch(error => {
                            console.log(error)
                            addAlert("Unable to approve.", "danger");
                            setClose();
                        })
                    }
                }
            }}>
                <FormControl>
                    <FormLabel> Comment: </FormLabel>
                    <Input required value={comment} onChange={(event) => {
                        setComment(event.target.value);
                    }}/>
                </FormControl>

                <Button type="submit" fullWidth sx={{my: 2}}>Send</Button>
            </form>
        </ModalDialog>
    </Modal>
}


export default PublishingModal