import { DialogContent, DialogTitle, Modal, ModalDialog, Table, Box, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { convertTime } from "../helpers/TimeConversionHelper";
import { GetFeedbackIcon } from "../helpers/IconGeneration";
import { getBackground, getColor } from "../helpers/GetColors";


function StatusesModal({open, setClose, statuses}) {
    const [statusesState, setStatusesState] = useState(null);



    useEffect(() => {
        setStatusesState(statuses);
    }, [])

    return <Modal open={open} onClose={() => setClose()}>
        <ModalDialog>
            <DialogTitle> Previous Report Statuses</DialogTitle>
            <DialogContent>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                Status:
                            </th>
                            <th>
                                Date Created:
                            </th>
                            <th>
                                Comment:
                            </th>
                        </tr>
                    </thead>
                    {statusesState && <tbody>
                        {statusesState.map((status, index) => {
                            return <tr>
                                <td>
                                    <Box sx={{display: "flex", width: "fit-content", p: 1, backgroundColor: getBackground(status["value"]), borderRadius: "8px", alignItems: "center"}}>
                                        <GetFeedbackIcon status={status["value"]} />
                                        <Typography color={getColor(status['value'])}> {status["value"].charAt(0).toUpperCase() + status["value"].slice(1)} </Typography>
                                    </Box>
                                </td>
                                <td>
                                    {convertTime(status["created_at"])}
                                </td>
                                <td>
                                    {status["comment"]}
                                </td>
                            </tr>
                        })}
                    </tbody>}
                </Table>
            </DialogContent>
        </ModalDialog>
    </Modal>
}

export default StatusesModal ;